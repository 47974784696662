<template>
  <div>
    <v-row class="tw-mb-14">
      <v-col>
        <h2 class="tw-text-lg tw-font-semibold tw-text-charcoal">
          Change Password
        </h2>
        <p class="tw-mb-0 tw-text-light-grey tw-text-base tw-leading-22">
          Please follow requirements below
        </p>
      </v-col>
    </v-row>
    <validation-observer ref="observer" v-slot="{ invalid }">
      <v-form @submit.prevent="updatePassword" ref="passwordUpdate">
        <v-col cols="12">
          <validation-provider
            v-slot="{ errors }"
            name="newPassword"
            rules="required|password"
          >
            <j-text-field
              color="secondary"
              v-model="newPassword"
              :type="showPassword ? 'text' : 'password'"
              :hide-details="errors.length === 0"
              :error-messages="errors"
              placeholder="Enter new password"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :prepend-inner-icon="'mdi-lock'"
              @toggle="showPassword = !showPassword"
            />
          </validation-provider>
        </v-col>
        <v-col cols="12">
          <validation-provider
            v-slot="{ errors }"
            name="Confirmation password"
            rules="required|confirmed:newPassword"
          >
            <j-text-field
              color="secondary"
              v-model="confirmation"
              :type="showPasswordConfirmation ? 'text' : 'password'"
              :hide-details="errors.length === 0"
              :error-messages="errors"
              placeholder="Confirm New Password"
              :append-icon="
                showPasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off'
              "
              :prepend-inner-icon="'mdi-lock'"
              @toggle="showPasswordConfirmation = !showPasswordConfirmation"
            />
          </validation-provider>
        </v-col>
        <span class="tw-hidden">{{ invalid }}</span>
      </v-form>
    </validation-observer>
    <v-col class="mb-4">
      <PasswordDisclaimer />
    </v-col>
  </div>
</template>
<script>
import PasswordDisclaimer from "@/components/shared/PasswordDisclaimer.vue";
import { PASSWORD } from "@/enums/validation-regex.js";

export default {
  name: "ChangePassword",
  props: ["user"],
  components: {
    PasswordDisclaimer
  },
  data() {
    return {
      newPassword: null,
      confirmation: null,
      passwordRegex: PASSWORD.pattern,
      showPassword: false,
      showPasswordConfirmation: false
    };
  },
  computed: {
    enable() {
      return (
        this.newPassword !== null &&
        this.confirmation !== null &&
        this.newPassword !== "" &&
        this.confirmation !== "" &&
        this.checkPasswordStrength(this.newPassword) &&
        this.newPassword === this.confirmation
      );
    }
  },
  watch: {
    enable: function (val) {
      if (val) {
        this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
          isDisabled: false
        });
      } else {
        this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
          isDisabled: true
        });
      }
    }
  },
  methods: {
    checkPasswordStrength(val) {
      return this.passwordRegex.test(val);
    },
    updatePassword() {
      const updatedUser = {
        password: this.newPassword
      };
      this.$store
        .dispatch("users/patch", [this.user.id, updatedUser])
        .then(() => {
          this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
            text: "Update in progress...",
            bgColor: "tw-bg-dark-green",
            textColor: "tw-text-chartreuse"
          });
          setTimeout(() => {
            this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
              text: "Password updated!",
              isDisabled: true
            });
            window.location.reload();
          }, 1500);
        });
    },
    resetPassword() {
      this.$router.push({
        name: "SendLink"
      });
    }
  },
  beforeMount() {
    this.$store.commit("meta/setHeader", {
      defaultHeaderDisabled: false,
      pageTitle: "Password",
      bgColor: "#6A4AE8",
      fontColor: "#FFFFFF",
      tagline: null,
      iconOne: null,
      iconTwo: null,
      showBackButton: true
    });
    this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
      button: true,
      isDisabled: true,
      text: "Update Password",
      method: this.updatePassword,
      bgColor: "tw-bg-chartreuse",
      textColor: "tw-text-charcoal"
    });
  },
  destroyed() {
    this.$store.commit("meta/setHeader", {
      defaultHeaderDisabled: false,
      pageTitle: "Profile",
      bgColor: "#6A4AE8",
      fontColor: "#FFFFFF",
      tagline: null,
      iconOne: null,
      iconTwo: null,
      showBackButton: true
    });
  }
};
</script>
